<script>
import Swal from "sweetalert2";
import Fixtures from "../../../services/Fixtures";

export default {
  emits: ["success"],
  data() {
    return {
      csrf_token: localStorage.getItem("csrf_token"),
      showModal: false,
      competition_id: null,
      teams: [],
      venues: [],
      competitions: [],
      home_team_id: null,
      away_team_id: null,
      season: null,
      selectedVenue: null,
      datetime: null,
      seasons: [
        {
          season_id: "0",
          season_name: "2022/2023",
        },
        {
          season_id: "1",
          season_name: "2023/2024",
        },
        {
          season_id: "2",
          season_name: "2024/2025",
        },
      ],
      tryingToSubmit: false,
    };
  },
  created() {
    
  },
  mounted(){
    this.getVenues();
    this.getSportCompetitions();
  },
  computed: {},
  watch: {
    competition_id(newVal) {
      this.filterTeams(newVal);
    }
  },
  methods: {
    async getSportCompetitions() {
      try {
        const response = await Fixtures.getSportCompetitions();
        console.log(response)
        this.competitions = response.data.competitions;
      } catch (error) {
        this.failedmsg(error.response.data.error);
      }
    },
    async getVenues() {
      try {
        const response = await Fixtures.getVenues();
        this.venues = response.data;
      } catch (error) {
        this.failedmsg(error.response.data.error);
      }
    },
    filterTeams(competitionId) {
      const competition = this.competitions.find(c => c.competition_id === competitionId);
      this.teams = competition ? competition.teams : [];
    },
    async addSportMatch() {
      const payload = {
        competition_id: this.competition_id,
        season: this.season,
        homeTeam: this.home_team_id,
        awayTeam: this.away_team_id,
        venueId: this.selectedVenue.venue_id,
        venueName: this.selectedVenue.venue_name,
        datetime: this.datetime,
      };
      try {
        this.tryingToSubmit = true;
        const response = await Fixtures.addSportMatch(payload);
        this.successmsg();
        this.$emit("success");
        this.closeModal();
        this.tryingToSubmit = false;
      } catch (error) {
        this.failedmsg(error.response.data.message);
        this.tryingToSubmit = false;
      }
    },
    closeModal() {
      this.showModal = false;
    },
    successmsg() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: `Match added!`,
        showConfirmButton: false,
        timer: 2000,
      });
    },

    failedmsg(msg) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: msg,
        showConfirmButton: false,
        timer: 50000,
      });
    },
  },
};
</script>

<template>
  <b-modal
    id="add_match"
    v-model="showModal"
    title="Add Match"
    title-class="text-black font-18"
    body-class="p-3"
    hide-footer
    centered
  >
    <form @submit.prevent="addSportMatch">
      <div class="row">
        <div class="col-12">

          <div class="mb-3">
            <label class="control-label form-label">Select Competition</label>
            <select
              v-model="competition_id"
              class="form-control form-select"
              name="competition_id"
              type="select"
            >
              <b-form-select-option :value="null"
                >Please select competition</b-form-select-option
              >
              <option
                v-for="competition in competitions"
                :key="competition.competition_id"
                :value="competition.competition_id"
              >
                {{ competition.competition_name }}
              </option>
            </select>
          </div>

          <div class="mb-3">
            <label class="control-label form-label">Select Season</label>
            <select
              v-model="season"
              class="form-control form-select"
              name="season"
              type="select"
            >
              <b-form-select-option :value="null"
                >Please select a season</b-form-select-option
              >
              <option
                v-for="season in seasons"
                :key="season.season_id"
                :value="season.season_name"
              >
                {{ season.season_name }}
              </option>
            </select>
          </div>
          <div class="mb-3">
            <label class="control-label form-label">Select Home Team</label>
            <select
              v-model="home_team_id"
              class="form-control form-select"
              name="home_team_id"
              type="select"
            >
              <b-form-select-option :value="null"
                >Please select home team</b-form-select-option
              >
              <option
                v-for="team in teams"
                :key="team.team_id"
                :value="team.team_id"
              >
                {{ team.team_name }}
              </option>
            </select>
          </div>
          <div class="mb-3">
            <label class="control-label form-label">Select Away Team</label>
            <select
              v-model="away_team_id"
              class="form-control form-select"
              name="away_team_id"
              type="select"
            >
              <b-form-select-option :value="null"
                >Please select away team</b-form-select-option
              >
              <option
                v-for="team in teams"
                :key="team.team_id"
                :value="team.team_id"
              >
                {{ team.team_name }}
              </option>
            </select>
          </div>
          <div class="mb-3">
            <label class="control-label form-label">Select Venue</label>
            <select
              v-model="selectedVenue"
              class="form-control form-select"
              name="venue"
              type="select"
            >
              <b-form-select-option :value="null"
                >Please select venue</b-form-select-option
              >
              <option
                v-for="venue in venues"
                :key="venue.venue_id"
                :value="venue"
              >
                {{ venue.venue_name }}
              </option>
            </select>
          </div>

          <div class="mb-3">
            <label class="control-label form-label">Select Date</label>
            <input
              class="form-control"
              v-model="datetime"
              type="datetime-local"
              name="datetime"
            />
          </div>
        </div>
      </div>

      <div class="text-end pt-5 mt-3">
        <b-button variant="light" @click="closeModal">Close</b-button>
        <b-button
          type="submit"
          variant="primary"
          class="ms-3"
          :disabled="tryingToSubmit"
        >
          {{ tryingToSubmit ? "Loading..." : "Submit" }}
        </b-button>
      </div>
      <input type="hidden" name="csrf_token" v-model="csrf_token" />
    </form>
  </b-modal>
</template>
